<template>
  <div>
    <div align="center" justify="center">
      <FullCalendar :options="calendarOptions" :events="events" />
    </div>
  </div>
</template>


<script>
import FullCalendar from '@fullcalendar/vue'
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import ruLocale from '@fullcalendar/core/locales/ru'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  computed: {
    isOperator() {
      return this.$store.getters.isOperator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    calendarOptions() {
      return {
        plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin, listPlugin],
        initialView: 'dayGridMonth',
        height: 'auto',
        dateClick: this.handleDateClick,
        eventClick: this.eventClick,
        events: this.events,
        eventOrder: 'priority,start,-duration,allDay,title',
        weekends: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        editable: false,
        firstDay: 1,
        views: {
          dayGridMonth: {
            eventOrder: "start,-duration,allDay,title"
          },
          // listMonth: {
          //   eventOrder: "start,-duration,allDay,title"
          // },
        },
        locales: [ruLocale],
        locale: this.$t('event_calendar.locale'),
        eventTimeFormat: { // like '14:30:00'
          hour: 'numeric',
          minute: '2-digit',
          hour12: false,
        },
        displayEventEnd: true,
        slotLabelFormat: {hour: 'numeric', minute: '2-digit', hour12: false}
      }
    },
    events() {
      return this.$store.state.events
    }
  },
  methods: {
    handleDateClick: function(arg) {
      console.log(arg)
      return false
    },
    eventClick: function(info) {
      if (!(this.isOperator && this.isLicenseValid)) {
        return false
      }

      this.$emit('editEvent', info.event.id)
    },
  },
}
</script>
