<template>
  <div>
    <v-data-table
      :headers="listEventHeaders"
      :items="events"
      :search='search'
      :no-data-text="$t('table_empty')"
      :custom-filter="filterTable"
      @click:row="handleEventClick"
      :footer-props="{
          pageText: $t('event_table.footer.page_text'),
          'items-per-page-text': $t('event_table.footer.items_per_page'),
          'items-per-page-all-text': $t('event_table.footer.items_per_page_all_text')
        }"
    >
      <template v-slot:top> 
        <v-toolbar flat>
          <v-toolbar-title
          @click="search = ''"
          style="cursor: pointer"
          >{{ $t('event_table.all_events') }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title
          @click="search = 'one_time'"
          style="cursor: pointer"
          >{{ $t('event_table.on_time_events') }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title
          @click="search = 'recurent'"
          style="cursor: pointer"
          >{{ $t('event_table.recurrent_events') }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.playlist="{ value }">
          {{ getPlaylist(value) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>

  export default {
    data: () => ({
      // showEventForm: false,
      // event: {},
      search: "",
    }),
    computed: {
      listEventHeaders() {
        return [
          { text: this.$t('event_table.headers.title'), value: 'title' },
          { text: this.$t('event_table.headers.playlist'), value: 'playlist' },
          { text: this.$t('event_table.headers.rrule'), value: 'rrule' },
          { text: this.$t('event_table.headers.start_date'), value: 'start_date' },
          { text: this.$t('event_table.headers.start_time'), value: 'start_time' },
          { text: this.$t('event_table.headers.all_day'), value: 'allDay' },
          { text: this.$t('event_table.headers.from_time'), value: 'from_time' },
          { text: this.$t('event_table.headers.to_time'), value: 'to_time' },
          { text: this.$t('event_table.headers.recurrent'), value: 'recurent' },
          { text: this.$t('event_table.headers.what_end'), value: 'what_end' },
          { text: this.$t('event_table.headers.until_date'), value: 'until_date' },
          { text: this.$t('event_table.headers.until_time'), value: 'until_time' },
          { text: this.$t('event_table.headers.priority'), value: 'priority' },
        ]
      },
      isOperator() {
        return this.$store.getters.isOperator
      },
      isLicenseValid() {
        return this.$store.getters['license/isLicenseValid']
      },
      events() {
        return this.$store.state.events
      },
      playlists() {
        return this.$store.state.playlists;
      },
    },
    methods: {
      getPlaylist (playlistId) {
        return this.playlists.find(playlist => playlist.id === playlistId).name
      },
      filterTable (value, search, item) {
        if (search === '') {
          return true
        } else if (search == 'recurent') {
          return item.recurent === true
        } else if (search == 'one_time') {
          return item.recurent === false
        }
        return false
      },
      handleEventClick (event) {
        if (!(this.isOperator && this.isLicenseValid)) {
          return false
        }
        this.$emit('editEvent', event.id)
      },
    },
  }

</script>
