<template>
  <div>
    <v-dialog
      v-model="eventDialog"
      persistent
      max-width="700px"
      height="900px"
    >
      <v-card>
          <v-card-title>
            <span class="headline" v-text="eventTitle"></span>
            <v-spacer></v-spacer>
            <v-icon v-show="isEdit" @click="deleteEvent">
              mdi-delete
            </v-icon>
          </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="isValid">
              <v-row justify="center">
                <v-col>
                  <v-text-field
                    :label="$t('event_form.title_label')"
                    v-model="recurentEvent.title"
                    :rules="[rules.required, rules.unique]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select
                    :items="playlists"
                    :label="$t('event_form.select_playlist_label')"
                    item-text="name"
                    item-value="id"
                    v-model="recurentEvent.playlist"
                    :rules="[rules.required]"
                    dense
                  ></v-select>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="5">
                  <v-radio-group
                    v-model="recurentEvent.recurent"
                    row
                  >
                    <v-radio
                      :label="$t('event_form.radio_on_time')"
                      :value="false"
                    ></v-radio>
                    <v-radio
                      :label="$t('event_form.radio_recurrent')"
                      :value="true"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-row>
                    {{ $t('event_form.priority') }}:
                  <v-text-field
                    v-model="recurentEvent.priority"
                    type="number"
                    solo
                    flat
                    min="0"
                    outlined
                    hide-details
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                  </v-row>
                </v-col>
                <v-col></v-col>
              </v-row>
              <v-container v-if="!recurentEvent.recurent">
                <v-row >
                  <v-col cols="6">
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="recurentEvent.start_date"
                          :label="$t('event_form.on_time_mode.date_label')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="recurentEvent.start_date"
                        :first-day-of-week="1"
                        :locale="$t('event_form.date_picker')"
                        :max="recurentEvent.what_end == 'date' ? recurentEvent.until_date : null"
                        @input="dateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="5" class="px-0">
                    <v-checkbox
                      v-model="recurentEvent.allDay"
                      :label="$t('event_form.on_time_mode.all_day')"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row justify="start">
                  <v-col>
                    <time-range-fields
                      :disabled="recurentEvent.allDay"
                      :from-time="recurentEvent.from_time"
                      @input-from-time="recurentEvent.from_time = $event"
                      :to-time="recurentEvent.to_time"
                      @input-to-time="recurentEvent.to_time = $event"
                    ></time-range-fields>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-else>
                <v-row>
                  <v-col>
                    <v-menu
                      v-model="start_dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="recurentEvent.start_date"
                          :label="$t('event_form.recurrent_mode.start_date')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="recurentEvent.start_date"
                        :first-day-of-week="1"
                        :locale="$t('event_form.date_picker')"
                        :max="recurentEvent.what_end == 'date' ? recurentEvent.until_date : null"
                        @input="start_dateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                      v-model="start_timeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="recurentEvent.start_time"
                          :label="$t('event_form.recurrent_mode.start_time')"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="recurentEvent.start_time"
                        full-width
                        format="24hr"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <span>{{ $t('event_form.recurrent_mode.frequency') }}:</span>
                    <v-radio-group
                      v-model="recurentEvent.freq"
                    >
                      <v-radio v-for="freq in freq_radio"
                        :key="freq"
                        :label="freq.text"
                        :value="freq.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  
                    <v-divider
                      vertical
                    ></v-divider>
                  
                  <v-col class="pb-0">
                    <v-container v-if="recurentEvent.freq == 'YEARLY'"  class="pb-0">
                      <v-row align="center">
                        {{ $tc('event_form.recurrent_mode.every', recurentEvent.interval) }}
                        <v-col cols="3">
                          <v-text-field
                            v-model="recurentEvent.interval"
                            type="number"
                            min="1"
                            max="99"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.required, rules.moreThanZero]"
                          ></v-text-field>
                        </v-col>
                        {{ $tc('event_form.recurrent_mode.year', recurentEvent.interval) }}
                      </v-row>
                      <v-row align="center">
                        <v-col cols="1" class="px-0">{{ $t('event_form.recurrent_mode.yearly_on') }}</v-col>
                        
                        <v-col cols="5">
                          <v-select
                            v-model="recurentEvent.bymonth"
                            :items="months"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.required]"
                          ></v-select>
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            v-model="recurentEvent.bymonthday"
                            :items="Array.from({length: 31}, (x, i) => [i + 1])"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.arrayRequired]"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row justify="start">
                        <v-col cols="3" class="px-0">
                          <v-checkbox
                            v-model="recurentEvent.allDay"
                            :label="$t('event_form.recurrent_mode.all_day')"
                          ></v-checkbox>
                        </v-col>
                        <v-col>
                          <time-range-fields
                            :disabled="recurentEvent.allDay"
                            :from-time="recurentEvent.from_time"
                            @input-from-time="recurentEvent.from_time = $event"
                            :to-time="recurentEvent.to_time"
                            @input-to-time="recurentEvent.to_time = $event"
                          ></time-range-fields>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-else-if="recurentEvent.freq == 'MONTHLY'"  class="pb-0">
                      <v-row align="center">
                        {{ $tc('event_form.recurrent_mode.every', recurentEvent.interval) }}
                        <v-col cols="3">
                          <v-text-field
                            v-model="recurentEvent.interval"
                            type="number"
                            min="1"
                            max="99"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.required, rules.moreThanZero]"
                          ></v-text-field>
                        </v-col>
                        {{ $tc('event_form.recurrent_mode.month', recurentEvent.interval) }}
                      </v-row>
                      <v-row justify="space-between">
                        <v-checkbox
                          v-for="num in 31"
                          :hide-details="num !== 31" 
                          :key="num"
                          v-model="recurentEvent.bymonthday"
                          :label="String(num)"
                          :value="num"
                          :rules="[rules.arrayRequired]"
                        ></v-checkbox>
                      </v-row>
                      <v-row justify="start">
                        <v-col cols="3" class="px-0">
                          <v-checkbox
                            v-model="recurentEvent.allDay"
                            :label="$t('event_form.recurrent_mode.all_day')"
                          ></v-checkbox>
                        </v-col>
                        <v-col>
                          <time-range-fields
                            :disabled="recurentEvent.allDay"
                            :from-time="recurentEvent.from_time"
                            @input-from-time="recurentEvent.from_time = $event"
                            :to-time="recurentEvent.to_time"
                            @input-to-time="recurentEvent.to_time = $event"
                          ></time-range-fields>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-else-if="recurentEvent.freq == 'WEEKLY'"  class="pb-0">
                      <v-row align="center">
                        {{ $tc('event_form.recurrent_mode.every_week', recurentEvent.interval) }}
                        <v-col cols="3">
                          <v-text-field
                            v-model="recurentEvent.interval"
                            type="number"
                            min="1"
                            max="99"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.required, rules.moreThanZero]"
                          ></v-text-field>
                        </v-col>
                        {{ $tc('event_form.recurrent_mode.week', recurentEvent.interval) }}
                      </v-row>
                      <v-row justify="space-between">
                        <v-checkbox 
                          v-for="day in weekdays_checkbox"
                          :hide-details="day !== weekdays_checkbox[weekdays_checkbox.length - 1]" 
                          :key="day"
                          v-model="recurentEvent.byweekday"
                          :label="day.text"
                          :value="day.value.toUpperCase()"
                          :rules="[rules.arrayRequired]"
                        ></v-checkbox>
                      </v-row>
                      <v-row justify="start">
                        <v-col cols="3" class="px-0">
                          <v-checkbox
                            v-model="recurentEvent.allDay"
                            :label="$t('event_form.recurrent_mode.all_day')"
                          ></v-checkbox>
                        </v-col>
                        <v-col>
                          <time-range-fields
                            :disabled="recurentEvent.allDay"
                            :from-time="recurentEvent.from_time"
                            @input-from-time="recurentEvent.from_time = $event"
                            :to-time="recurentEvent.to_time"
                            @input-to-time="recurentEvent.to_time = $event"
                          ></time-range-fields>
                        </v-col>
                      </v-row>

                    </v-container>
                    <v-container v-else-if="recurentEvent.freq == 'DAILY'">
                      <v-row align="center">
                        {{ $tc('event_form.recurrent_mode.every', recurentEvent.interval) }}
                        <v-col cols="3">
                          <v-text-field
                            v-model="recurentEvent.interval"
                            type="number"
                            min="1"
                            max="99"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.required, rules.moreThanZero]"
                          ></v-text-field>
                        </v-col>
                        {{ $tc('event_form.recurrent_mode.days', recurentEvent.interval) }}
                      </v-row>
                      <v-row></v-row>
                      <v-row justify="start">
                        <v-col cols="3" class="px-0">
                          <v-checkbox
                            v-model="recurentEvent.allDay"
                            :label="$t('event_form.recurrent_mode.all_day')"
                          ></v-checkbox>
                        </v-col>
                        <v-col>
                          <time-range-fields
                            :disabled="recurentEvent.allDay"
                            :from-time="recurentEvent.from_time"
                            @input-from-time="recurentEvent.from_time = $event"
                            :to-time="recurentEvent.to_time"
                            @input-to-time="recurentEvent.to_time = $event"
                          ></time-range-fields>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-else-if="recurentEvent.freq == 'HOURLY'">
                      <v-row align="center">
                        {{ $tc('event_form.recurrent_mode.every', recurentEvent.interval) }}
                        <v-col cols="3">
                          <v-text-field
                            v-model="recurentEvent.interval"
                            type="number"
                            min="1"
                            max="99"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.required, rules.moreThanZero]"
                          ></v-text-field>
                        </v-col>
                        {{ $tc('event_form.recurrent_mode.hours', recurentEvent.interval) }}
                      </v-row>
                      <v-row>
                      </v-row>
                      <v-row align="center">
                        {{ $t('event_form.recurrent_mode.from_minutes') }}
                        <v-col cols="3">
                          <v-text-field
                            v-model="recurentEvent.from_min"
                            type="number"
                            min="0"
                            :max="recurentEvent.to_min - 1"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        {{ $t('event_form.recurrent_mode.to_minutes') }}
                        <v-col cols="3">
                          <v-text-field
                            v-model="recurentEvent.to_min"
                            type="number"
                            :min="recurentEvent.from_min + 1"
                            max="60"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        {{ $tc('event_form.recurrent_mode.minutes', recurentEvent.to_min) }}
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
                <v-container>
                  <v-row align="center">
                    <v-col cols="2">
                      {{ $t('event_form.recurrent_mode.when_is_the_end') }}
                    </v-col>
                    <v-col>
                      <v-radio-group
                        v-model="recurentEvent.what_end"
                        row
                      >
                        <v-radio
                          :label="$t('event_form.recurrent_mode.radio_date')"
                          value="date"
                        ></v-radio>
                        <v-radio
                          :label="$t('event_form.recurrent_mode.radio_repetitions')"
                          value="repetitions"
                        ></v-radio>
                        <v-radio
                          :label="$t('event_form.recurrent_mode.radio_never')"
                          value="never"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="recurentEvent.what_end == 'date'">
                    <v-col>
                      <v-menu
                        v-model="until_dateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="recurentEvent.until_date"
                            :label="$t('event_form.recurrent_mode.end_date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[rules.required, rules.moreThanStartDate]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="recurentEvent.until_date"
                          :first-day-of-week="1"
                          @input="endDateMenu = false"
                          :min="recurentEvent.start_date"
                          :locale="$t('event_form.date_picker')"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="until_timeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="recurentEvent.until_time"
                            :label="$t('event_form.recurrent_mode.end_time')"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="recurentEvent.until_time"
                          full-width
                          format="24hr"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="recurentEvent.what_end == 'repetitions'" align="center" justify="center">
                    <v-col cols="2" class="pr-0">
                      {{ $t('event_form.recurrent_mode.repetitions') }}:
                    </v-col>
                    <v-col>
                      <v-col cols="3">
                          <v-text-field
                            v-model="recurentEvent.count"
                            type="number"
                            min="1"
                            max="99"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                    </v-col>
                  </v-row>
                </v-container>
              </v-container>
            </v-form>
          </v-container>
          <small>*{{ $t('event_form.recurrent_mode.indicates_required_field') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary--text" @click="close">
            {{ $t('event_form.recurrent_mode.cancel_button') }}
          </v-btn>
          <v-btn :disabled="!isValid" color="primary--text" @click="save">
            {{ $t('event_form.recurrent_mode.save_button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="510px">
      <v-card>
        <v-card-title class="headline">{{ $t('event_form.dialog_delete.headline') }}?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary--text" @click="closeDelete">{{ $t('event_form.dialog_delete.cancel_button') }}</v-btn>
          <v-btn color="primary--text" @click="deleteEventConfirm">{{ $t('event_form.dialog_delete.ok_button') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import TimeRangeFields from '../components/TimeRangeFields.vue'

export default {
  components: {
    TimeRangeFields,
  },
  props: [
    'value',
    'event',
  ],
  data() {
    return {
      time: null,
      dialogDelete: false,
      start_timeMenu: false,
      until_timeMenu: false,
      dateMenu: false,
      start_dateMenu: false,
      until_dateMenu: false,
      isValid: true,
      recurentEvent: {
        title: '',
        playlist: undefined,
        recurent: false,
        priority: 1,
        start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        start_time: '00:00',
        until_date: undefined,
        until_time: undefined,
        from_time: undefined,
        to_time: undefined,
        from_min: undefined,
        to_min: undefined,
        what_end: 'never',
        count: 1,
        interval: 1,
        freq: 'DAILY',
        bymonth: undefined,
        bymonthday: [],
        byweekday: [],
        allDay: false,
      },
      recurentEventDefault: {
        title: '',
        playlist: undefined,
        priority: 1,
        recurent: false,
        // start
        start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        start_time: '00:00',
        // end
        what_end: 'never',
        until_date: undefined,
        until_time: undefined,
        count: 1, // repetitions count
        // freq
        freq: 'DAILY',
        // freq properties
        interval: 1,
        from_time: undefined,
        to_time: undefined,
        from_min: undefined,
        to_min: undefined,
        bymonth: undefined,
        bymonthday: [],
        byweekday: [],
        allDay: false,
      },
      eventId: undefined,
    }
  },
  computed: {
    isEdit () {
      return Object.keys(this.event).length !== 0
    },
    eventDialog: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    },
    rules() { 
      return {
        required: value => !!value || value === 0 || this.$t('event_form.rules.required'),
        // weeklyRequired: value => {
        //   return this.recurentEvent.freq === 'WEEKLY' ? this.rules.required(!!value.length) : !value
        // },
        arrayRequired: value => {
          return !!value.length || value === 0 || this.$t('event_form.rules.array_required')
        },
        unique: value => !this.events.find(event => event.title == value) || this.isEdit || this.$t('event_form.rules.unique'),
        moreThanZero: value => value > 0  || "Must be more than zero",
        moreThanStartDate: value => new Date(value).getTime() > new Date(this.recurentEvent.start_date).getTime() || this.$t('event_form.rules.date'),
      }
    },
    playlists() {
      return this.$store.state.playlists;
    },
    freq_radio() {
      return [
          { text: this.$t('event_form.recurrent_mode.freq_radio_yearly'), value: 'YEARLY' },
          { text: this.$t('event_form.recurrent_mode.freq_radio_monthly'), value: 'MONTHLY' },
          { text: this.$t('event_form.recurrent_mode.freq_radio_weekly'), value: 'WEEKLY' },
          { text: this.$t('event_form.recurrent_mode.freq_radio_daily'), value: 'DAILY' },
          { text: this.$t('event_form.recurrent_mode.freq_radio_hourly'), value: 'HOURLY' },
      ]
    },
    months() {
      return [
        { text: this.$t('event_form.recurrent_mode.january'), value: 'January' },
        { text: this.$t('event_form.recurrent_mode.february'), value: 'February' },
        { text: this.$t('event_form.recurrent_mode.march'), value: 'March' },
        { text: this.$t('event_form.recurrent_mode.april'), value: 'April' },
        { text: this.$t('event_form.recurrent_mode.may'), value: 'May' },
        { text: this.$t('event_form.recurrent_mode.june'), value: 'June' },
        { text: this.$t('event_form.recurrent_mode.july'), value: 'July' },
        { text: this.$t('event_form.recurrent_mode.august'), value: 'August' },
        { text: this.$t('event_form.recurrent_mode.september'), value: 'September' },
        { text: this.$t('event_form.recurrent_mode.october'), value: 'October' },
        { text: this.$t('event_form.recurrent_mode.november'), value: 'November' },
        { text: this.$t('event_form.recurrent_mode.december'), value: 'December' },
      ]
    },
    weekdays_checkbox() {
      return [
        { text: this.$t('event_form.recurrent_mode.monday'), value: 'mo' },
        { text: this.$t('event_form.recurrent_mode.tuesday'), value: 'tu' },
        { text: this.$t('event_form.recurrent_mode.wednesday'), value: 'we' },
        { text: this.$t('event_form.recurrent_mode.thursday'), value: 'th' },
        { text: this.$t('event_form.recurrent_mode.friday'), value: 'fr' },
        { text: this.$t('event_form.recurrent_mode.saturday'), value: 'sa' },
        { text: this.$t('event_form.recurrent_mode.sunday'), value: 'su' }
      ]
    },
    eventTitle() {
      return !this.isEdit ? this.$t('event_form.title.new') : this.$t('event_form.title.edit')
    },
    events() {
      return this.$store.state.events
    }
  },
  methods: {
    preparestart_time(obj) {
      let startHour = obj.start_time.split(':')[0]
      let startMin = obj.start_time.split(':')[1]
      let start_date = obj.start_date.replaceAll('-', '')
      return `DTSTART:${start_date}T${startHour}${startMin}00\n`
    },
    prepareuntil_time(obj) {
      let untilHour = obj.until_time.split(':')[0]
      let untilMin = obj.until_time.split(':')[1]
      let until_date = obj.until_date.replaceAll('-', '')
      return `UNTIL=${until_date}T${untilHour}${untilMin}00`
    },
    prepareCount(obj){
      return `COUNT=${obj.count}`
    },

    prepareRrule(obj) {
      let rrule
      let minutes
      let freq = `FREQ=${obj.freq};`
      let interval = `INTERVAL=${obj.interval};`
      let byhour = ''
      let byminute = ''
      let byday = ''
      let bymonthday = ''
      let bymonth = ''

      if (!obj.allDay & obj.freq != 'HOURLY') {
        let [fromHour, from_min] = obj.from_time.split(':')
        let [toHour, to_min] = obj.to_time.split(':')
        byhour = `BYHOUR=${fromHour};`
        byminute = `BYMINUTE=${from_min};`
        minutes = this.calculate_duration(fromHour, from_min, toHour, to_min)
      } else {minutes = 0}
      let duration = minutes ? {minutes: minutes} : ""

      if (obj.freq == 'HOURLY') {
        byminute = `BYMINUTE=${obj.from_min};`
        minutes = parseInt(obj.to_min) - parseInt(obj.from_min)
        duration = {minutes: minutes}
      } else if (obj.freq == 'WEEKLY') {
        byday = `BYDAY=${obj.byweekday.join()};`
      } else if (obj.freq == 'MONTHLY') {
        bymonthday = `BYMONTHDAY=${obj.bymonthday.join()};`
      } else if (obj.freq == 'YEARLY') {
        bymonth = `BYMONTH=${this.months.findIndex((month) => month == obj.bymonth) + 1};`
        bymonthday = `BYMONTHDAY=${obj.bymonthday.join()};`
      }
      rrule = "RRULE:" + freq + interval + bymonth + bymonthday + byday + byhour + byminute
      return {rrule: rrule, duration: duration}
    },
    prepareUntil(obj) {
      let until = ''
      if (obj.what_end == 'date') {
        until = this.prepareuntil_time(obj)
      } else if (obj.what_end == 'repetitions') {
        until = this.prepareCount(obj)
      }
      return until
    },
    calculate_duration(fromHour, from_min, toHour, to_min) {
      let durationInMinutes
      let totalMinInDay = 24 * 60
      let fromDayMinutes = parseInt(fromHour)*60 + parseInt(from_min)
      let toDayMinutes = parseInt(toHour)*60 + parseInt(to_min)
      
      if (fromDayMinutes < toDayMinutes) {
        durationInMinutes = toDayMinutes  - fromDayMinutes
      } else {
        durationInMinutes = totalMinInDay - fromDayMinutes + toDayMinutes
      }
      
      return durationInMinutes
    },
    calculateWeight(obj) {
      if (!obj.recurent) {return 100}
      else if (obj.title == 'default') {return 0}
      else if (obj.freq == 'YEARLY') {return 50}
      else if (obj.freq == 'MONTHLY') {return 40}
      else if (obj.freq == 'WEEKLY') {return 30}
      else if (obj.freq == 'DAILY') {return 20}
      else if (obj.freq == 'HOURLY') {return 10}
    },
    makeEvent(obj) {
      let dstart = this.preparestart_time(obj)
      let until = this.prepareUntil(obj)
      let rrule_obj = this.prepareRrule(obj)
      let duration = rrule_obj.duration
      let weight = this.calculateWeight(obj)
      let rrule = dstart + rrule_obj.rrule + until
      if (!until) {rrule = rrule.replace(/.$/,"")}
      let event = {
        title: obj.title,
        allDay: obj.allDay,
        recurent: obj.recurent,
        duration: duration,
        rrule: rrule,
        what_end: obj.what_end,
        playlist: obj.playlist,
        start_date: obj.start_date,
        start_time: obj.start_time,
        until_date: obj.until_date,
        until_time: obj.until_time,
        from_time: obj.from_time,
        to_time: obj.to_time,
        from_min: obj.from_min,
        to_min: obj.to_min,
        count: obj.count,
        interval: obj.interval,
        freq: obj.freq,
        bymonth: obj.bymonth,
        bymonthday: obj.bymonthday,
        byweekday: obj.byweekday,
        weight: weight,
        priority: obj.priority,
      }
      return event
    },
    prepareOneTimeEvent() {
      this.recurentEvent.what_end = 'repetitions'
      this.recurentEvent.count = 1
      this.recurentEvent.interval = 1
      this.recurentEvent.freq = 'DAILY'
      this.recurentEvent.start_time = '00:00'
    },
    close () {
      this.eventDialog = false
      this.$nextTick(() => {
        this.recurentEvent = Object.assign({}, this.recurentEventDefault)
      })
    },
    save () {
      if (!this.recurentEvent.recurent) {
        this.prepareOneTimeEvent()
      }
      let event = this.makeEvent(this.recurentEvent)
      if (!this.isEdit) {
        this.$store.dispatch('addEvent', event)
      } else {
        event.id = this.recurentEvent.id
        this.$store.dispatch('updateEvent', event)
      }
      this.close()
    },
    deleteEvent() {
      this.eventId = this.recurentEvent.id
      this.eventDialog = false
      this.dialogDelete = true

    },
    deleteEventConfirm () {
      this.$store.dispatch('deleteEvent', this.eventId)
      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.recurentEvent = Object.assign({}, this.recurentEventDefault)
        this.eventId = undefined
      })
    },
  },
  mounted() {
    this.$store.dispatch("getPlaylists");
    this.$store.dispatch('getEvents')
  },
  watch: {
    event (newVal) {
      if (this.isEdit) {
        this.recurentEvent = {...newVal}
      } else {
        this.recurentEvent = Object.assign({}, this.recurentEventDefault)
      }
    },
    'recurentEvent.freq' (newVal) {
      if (newVal === "HOURLY") {
        this.recurentEvent.allDay = false
      }
    },
  },
}
</script>
