<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          color="primary--text"
          @click.stop="showEventForm=true"
          class="mb-4 mx-10"
          v-if="isOperator && isLicenseValid"
        >
            {{ $t('scheduler_page.add_event_button') }}
        </v-btn>
      </v-col>
      <v-col>
        <v-tabs
          color="primary--text"
          right
          v-model="tab"
        >
          <v-tab>{{ $t('scheduler_page.calendar_button') }}</v-tab>
          <v-tab>{{ $t('scheduler_page.list_button') }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <event-form v-model="showEventForm" :event="event"></event-form>
    <event-table @editEvent="editEvent($event)" v-if="tab == 1"></event-table>
    <event-calendar class="mt-3" @editEvent="editEvent($event)" v-if="tab == 0"></event-calendar>
  </div>
</template>

<script>
  import EventForm from '../components/eventForm.vue'
  import EventTable from '../components/eventTable.vue'
  import EventCalendar from '../components/eventCalendar.vue'

  export default {
    components: {
      EventForm,
      EventTable,
      EventCalendar,
    },
    data: () => ({
      tab: null,
      showEventForm: false,
      event: {},
      eventId: null,
    }),
    computed: {
      isOperator() {
        return this.$store.getters.isOperator
      },
      isLicenseValid() {
        return this.$store.getters['license/isLicenseValid']
      },
      events() {
        return this.$store.state.events
      },
      playlists() {
        return this.$store.state.playlists;
      },
    },
    methods: {
      editEvent(eventId) {
        let rawEvent = this.events.find(event => event.id == eventId)
        this.event = Object.assign({}, rawEvent)
        this.showEventForm = true
      },
    },
    mounted() {
      this.$store.dispatch('getEvents')
    },
    watch: {
      showEventForm (newVal) {
        if (!newVal) {
          this.event = {}
          this.eventId = null
        }
      }
    },
  }
</script>