<template>
  <v-row>
    <v-col>
      <v-menu
        v-model="fromTimeMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="fromTime"
            @input="$emit('input-from-time', $event)"
            :label="$t('time_range_fields.from_time')"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            :rules="disabled ? [] : [rules.required]"
          ></v-text-field>
        </template>
        <v-time-picker
          :value="fromTime"
          @input="$emit('input-from-time', $event)"
          full-width
          format="24hr"
        ></v-time-picker>
      </v-menu>
    </v-col>
    <v-col
    >
      <v-menu
        v-model="toTimeMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="toTime"
            @input="$emit('input-to-time', $event)"
            :label="$t('time_range_fields.to_time')"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            :rules="disabled ? [] : [rules.required]"
          ></v-text-field>
        </template>
        <v-time-picker
          :value="toTime"
          @input="$emit('input-to-time', $event)"
          full-width
          format="24hr"
        ></v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    props: [
      'disabled',
      'fromTime',
      'toTime',
    ],
    data: function () {
      return {
        time: null,
        // From time
        fromTimeMenu: false,
        // To time
        toTimeMenu: false,
      }
    },
    computed: {
      rules() {
        return {
          required: value => !!value || value === 0 || this.$t('time_range_fields.required'),
        }
      },
    },
  }
</script>
